.pcs-global-menu {
  position: relative;
  z-index: var(--z-index-header, 1000);
  height: var(--navbar-height);
}

.pcs-global-menu--withdrawn {
  animation: ease 0.3s header--withdraw forwards;
}

@keyframes header--withdraw {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
