/* Any locale related Text rules to go here */

/**
 * Japanese
 * It's not clear if this is still needed or if it's just for Japanese, it was a global rule in reset.css, added in 2019. 
 * We had an issue with the japanese market that they needed to be able to control line breaks in headings, 
 * since accidental line breaks can change the entire meaning of a sentence in japanese. 
 * There was something about how Contentful escapes characters and with white-space: pre-line the japanese market 
 * could add \n in the texts in Contentful.
 */
html[lang="ja"] :where(h1, h2, h3, h4, h5, h6, p) {
  white-space: pre-line;
}

/* Korean */
html[lang="ko"] {
  word-break: keep-all;
}
