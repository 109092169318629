@media print {
  /* Set a good printing font size, set on HTML so REMs inherit base size */
  html {
    font-size: 8pt;
  }

  /* Hide the navigation control, this could to break if RB updates the header! */
  .rb3-menu-header__buttons-left,
  .rb3-menu-header__buttons-wrapper,
  .rb3-menu-header__nav-wrapper {
    display: none !important;
  }
}
