.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-shade);
  padding: 16px 16px 40px;
  width: 100%;
  min-height: 100vh;
  text-align: center;
}

.headline {
  margin-bottom: 20px;
  color: var(--color-text-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-44px);
  line-height: 1.09;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    font-size: var(--font-size-64px);
    line-height: 1.06;
  }
}

.link {
  color: var(--color-primary);
}
