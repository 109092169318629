@import "../../../node_modules/@cosmos/foundation/lib/primitives/index.css";

/* PostCSS converts into old style CSS, use like `@media (--breakpoint-medium) { .. }` */
@custom-media --breakpoint-medium (width >= 650px);
@custom-media --breakpoint-large (width >= 1000px);
@custom-media --breakpoint-x-large (width >= 1200px);
@custom-media --breakpoint-xx-large (width >= 1350px);

:root {
  /* COSMOS COLOURS */
  --color-primary: var(--cosmos-color-primary, #db0a40);
  --color-primary-darker: var(--cosmos-color-primary-darker, #c3093b);
  --color-primary-lighter: var(--cosmos-color-primary-lighter, #f50b48);

  /* COSMOS SURFACE COLOURS
     Use surface colors to style the surface of interface components
     like backgrounds, borders, and box-shadows. */

  /* Cosmos Suface Solid Dark */

  --color-surface-solid-dark: var(--cosmos-color-surface-solid-dark, #00162b);
  --color-surface-solid-dark-darker: var(
    --cosmos-color-surface-solid-dark-darker,
    #000f1e
  );
  --color-surface-solid-dark-lighter: var(
    --cosmos-color-surface-solid-dark-lighter,
    #001c39
  );

  /* Cosmos Suface solid Light */

  --color-surface-solid-light: var(--cosmos-color-surface-solid-light, #f8f8f8);
  --color-surface-solid-light-darker: var(
    --cosmos-color-surface-solid-light-darker,
    #efefef
  );
  --color-surface-solid-light-lighter: var(
    --cosmos-color-surface-solid-light-lighter,
    #ffffff
  );

  /* Cosmos Suface Glass Dark */

  --color-surface-glass-dark-90: var(
    --cosmos-color-surface-glass-dark-90,
    rgba(0, 15, 30, 0.9)
  );
  --color-surface-glass-dark-80: var(
    --cosmos-color-surface-glass-dark-80,
    rgba(0, 15, 30, 0.8)
  );
  --color-surface-glass-dark-60: var(
    --cosmos-color-surface-glass-dark-60,
    rgba(0, 15, 30, 0.6)
  );
  --color-surface-glass-dark-45: var(
    --cosmos-color-surface-glass-dark-45,
    rgba(0, 15, 30, 0.45)
  );
  --color-surface-glass-dark-20: var(
    --cosmos-color-surface-glass-dark-20,
    rgba(0, 15, 30, 0.2)
  );
  --color-surface-glass-dark-10: var(
    --cosmos-color-surface-glass-dark-10,
    rgba(0, 15, 30, 0.1)
  );
  --color-surface-glass-dark-5: var(
    --cosmos-color-surface-glass-dark-5,
    rgba(0, 15, 30, 0.05)
  );

  /* Cosmos Suface Glass Light */

  --color-surface-glass-light-90: var(
    --cosmos-color-surface-glass-light-90,
    rgba(255, 255, 255, 0.9)
  );
  --color-surface-glass-light-80: var(
    --cosmos-color-surface-glass-light-80,
    rgba(255, 255, 255, 0.8)
  );
  --color-surface-glass-light-60: var(
    --cosmos-color-surface-glass-light-60,
    rgba(255, 255, 255, 0.6)
  );
  --color-surface-glass-light-45: var(
    --cosmos-color-surface-glass-light-45,
    rgba(255, 255, 255, 0.45)
  );
  --color-surface-glass-light-40: var(
    --cosmos-color-surface-glass-light-40,
    rgba(255, 255, 255, 0.4)
  );
  --color-surface-glass-light-20: var(
    --cosmos-color-surface-glass-light-20,
    rgba(255, 255, 255, 0.2)
  );
  --color-surface-glass-light-10: var(
    --cosmos-color-surface-glass-light-10,
    rgba(255, 255, 255, 0.1)
  );
  --color-surface-glass-light-5: var(
    --cosmos-color-surface-glass-light-5,
    rgba(255, 255, 255, 0.05)
  );

  /* COSMOS TEXT COLOURS */
  --color-text-dark: var(--cosmos-color-text-dark, #000f1e);
  --color-text-dark-subtle: var(
    --cosmos-color-text-dark-subtle,
    rgba(0, 15, 30, 0.6)
  );
  --color-text-light: var(--cosmos-color-text-light, #ffffff);
  --color-text-light-subtle: var(
    --cosmos-color-text-light-subtle,
    rgba(255, 255, 255, 0.6)
  );

  /* COSMOS INFORM STATE COLOURS */
  --color-positive: var(--cosmos-color-positive, #159d48);
  --color-negative: var(--cosmos-color-negative, #db0a40);
  --color-inform: var(--cosmos-color-inform, #e06600);

  /* FONT FAMILIES */
  --ff-bull: "Bull", Helvetica, sans-serif;
  --ff-bull-text: "Bull Text", Helvetica, sans-serif;
  --ff-futura: "futura-pt-condensed", Bull, Helvetica, sans-serif;
  --font-family-heading: "Bull", Helvetica, sans-serif;

  /* FONT WEIGHTS */
  --font-weight-heading: 700;

  /* CONTAINER WIDTHS & PADDING */
  --max-width: 1350px; /* deprecated: meant to be used with `padding-inline: var(--spacing-sides)`, which is confusing */
  --max-width-sm: 600px;
  --max-width-base: 1222px;
  --max-width-revamp-base: 1472px;
  --spacing-top: 24px;
  --spacing-bottom: 110px;
  --spacing-sides: 24px;

  /* NAVIGATION */
  --navbar-height: 56px;

  /* COSMOS OUTLINE/FOCUS STYLES
     Sometimes we can't use Cosmos components, so we can use this to create consistent focus styles for buttons */
  --color-focus: var(--cosmos-color-accent-focus, #1b6aee);
  --button-outline-offset: 2px;
  --button-outline-style: solid;
  --button-outline-width: 2px;
  --focus-box-shadow: var(--color-focus, #1b6aee) 0px 0px 0px 2px inset,
    rgb(255, 255, 255) 0px 0px 0px 2px;

  /* FONT-SIZES 
     These will be converted to a smaller set of Cosmos based sizes */
  --font-size-10px: calc(10 / 16 * 1rem);
  --font-size-11px: calc(11 / 16 * 1rem);
  --font-size-12px: calc(12 / 16 * 1rem);
  --font-size-13-point-5px: calc(13.5 / 16 * 1rem);
  --font-size-14px: calc(14 / 16 * 1rem);
  --font-size-15px: calc(15 / 16 * 1rem);
  --font-size-16px: calc(16 / 16 * 1rem);
  --font-size-18px: calc(18 / 16 * 1rem);
  --font-size-20px: calc(20 / 16 * 1rem);
  --font-size-21px: calc(21 / 16 * 1rem);
  --font-size-22px: calc(22 / 16 * 1rem);
  --font-size-24px: calc(24 / 16 * 1rem);
  --font-size-25px: calc(25 / 16 * 1rem);
  --font-size-26px: calc(26 / 16 * 1rem);
  --font-size-28px: calc(28 / 16 * 1rem);
  --font-size-30px: calc(30 / 16 * 1rem);
  --font-size-32px: calc(32 / 16 * 1rem);
  --font-size-34px: calc(34 / 16 * 1rem);
  --font-size-36px: calc(36 / 16 * 1rem);
  --font-size-37px: calc(37 / 16 * 1rem);
  --font-size-38px: calc(38 / 16 * 1rem);
  --font-size-40px: calc(40 / 16 * 1rem);
  --font-size-42px: calc(42 / 16 * 1rem);
  --font-size-44px: calc(44 / 16 * 1rem);
  --font-size-45px: calc(45 / 16 * 1rem);
  --font-size-47px: calc(47 / 16 * 1rem);
  --font-size-48px: calc(48 / 16 * 1rem);
  --font-size-50px: calc(50 / 16 * 1rem);
  --font-size-54px: calc(54 / 16 * 1rem);
  --font-size-56px: calc(56 / 16 * 1rem);
  --font-size-60px: calc(60 / 16 * 1rem);
  --font-size-64px: calc(64 / 16 * 1rem);
  --font-size-68px: calc(68 / 16 * 1rem);
  --font-size-70px: calc(70 / 16 * 1rem);
  --font-size-72px: calc(72 / 16 * 1rem);
  --font-size-76px: calc(76 / 16 * 1rem);
  --font-size-80px: calc(80 / 16 * 1rem);
  --font-size-85px: calc(85 / 16 * 1rem);
  --font-size-88px: calc(88 / 16 * 1rem);
  --font-size-96px: calc(96 / 16 * 1rem);
  --font-size-100px: calc(100 / 16 * 1rem);
  --font-size-125px: calc(125 / 16 * 1rem);
  --font-size-160px: calc(160 / 16 * 1rem);

  /* TEXT LINKS */
  --link-text-decoration: underline;
  --link-text-underline-offset: 0.125em;
  --link-text-decoration-thickness: 0.105em;
  --link-text-decoration-color: currentColor;
  --link-text-decoration-color-hover: color-mix(
    in srgb,
    currentColor 50%,
    transparent
  );

  /* LINE HEIGHTS */
  --type-xxxx-large-line-height: var(--cosmos-type-xxxx-large-line-height, 1);
  --type-xxx-large-line-height: var(
    --cosmos-type-xxx-large-line-height,
    1.066667
  );
  --type-xx-large-line-height: var(
    --cosmos-type-xx-large-line-height,
    1.097561
  );
  --type-x-large-line-height: var(--cosmos-type-x-large-line-height, 1.137931);
  --type-large-line-height: var(--cosmos-type-large-line-height, 1.333333);
  --type-medium-line-height: var(--cosmos-type-medium-line-height, 1.5);
  --type-small-line-height: var(--cosmos-type-small-line-height, 1.411765);
  --type-x-small-line-height: var(--cosmos-type-x-small-line-height, 1.357143);
  --type-xx-small-line-height: var(--cosmos-type-xx-small-line-height, 1.25);

  /* FLUID TYPE
     Custom Fluid Type based on Cosmos, 380px to 1200px, assumes 16px base.
     Use this until we fully moved over to Cosmos type components */

  /* 45.00px → 86.00px */
  --fluid-type-xxxx-large: clamp(2.81rem, calc(1.63rem + 5vw), 5.38rem);

  /* 37.00px → 60.00px */
  --fluid-type-xxx-large: clamp(2.31rem, calc(1.65rem + 2.8vw), 3.75rem);

  /* 31.00px → 41.00px */
  --fluid-type-xx-large: clamp(1.94rem, calc(1.65rem + 1.22vw), 2.56rem);

  /* 26.00px → 29.00px */
  --fluid-type-x-large: clamp(1.63rem, calc(1.54rem + 0.37vw), 1.81rem);

  /* 22.00px → 24.00px */
  --fluid-type-large: clamp(1.38rem, calc(1.32rem + 0.24vw), 1.5rem);

  /* 18.00px → 20.00px */
  --fluid-type-medium: clamp(1.13rem, calc(1.07rem + 0.24vw), 1.25rem);

  /* 17.00px → 17.00px */
  --fluid-type-small: clamp(1.06rem, calc(1.06rem + 0vw), 1.06rem);

  /* 14.00px → 14.00px */
  --fluid-type-x-small: clamp(0.88rem, calc(0.88rem + 0vw), 0.88rem);

  /* 12.00px → 12.00px */
  --fluid-type-xx-small: clamp(0.75rem, calc(0.75rem + 0vw), 0.75rem);

  /* Font spacings */
  --type-xxxx-large-spacing-top: var(
    --cosmos-type-xxxx-large-spacing-top,
    0.55814em
  );
  --type-xxxx-large-spacing-bottom: var(
    --cosmos-type-xxxx-large-spacing-bottom,
    0.255814em
  );
  --type-xxx-large-spacing-top: var(
    --cosmos-type-xxx-large-spacing-top,
    0.666667em
  );
  --type-xxx-large-spacing-bottom: var(
    --cosmos-type-xxx-large-spacing-bottom,
    0.333333em
  );
  --type-xx-large-spacing-top: var(
    --cosmos-type-xx-large-spacing-top,
    0.829268em
  );
  --type-xx-large-spacing-bottom: var(
    --cosmos-type-xx-large-spacing-bottom,
    0.439024em
  );
  --type-x-large-spacing-top: var(
    --cosmos-type-x-large-spacing-top,
    1.083333em
  );
  --type-x-large-spacing-bottom: var(
    --cosmos-type-x-large-spacing-bottom,
    0.5em
  );
  --type-large-spacing-top: var(--cosmos-type-large-spacing-top, 1.083333em);
  --type-large-spacing-bottom: var(--cosmos-type-large-spacing-bottom, 0.5em);
  --type-medium-spacing-top: var(--cosmos-type-medium-spacing-top, 0.6em);
  --type-medium-spacing-bottom: var(--cosmos-type-medium-spacing-bottom, 0.4em);
  --type-small-spacing-top: var(--cosmos-type-small-spacing-top, 0.705882em);
  --type-small-spacing-bottom: var(
    --cosmos-type-small-spacing-bottom,
    0.470588em
  );
  --type-x-small-spacing-top: var(
    --cosmos-type-x-small-spacing-top,
    0.714286em
  );
  --type-x-small-spacing-bottom: var(
    --cosmos-type-x-small-spacing-bottom,
    0.428571em
  );
  --type-xx-small-spacing-top: var(
    --cosmos-type-xx-small-spacing-top,
    0.666667em
  );
  --type-xx-small-spacing-bottom: var(
    --cosmos-type-xx-small-spacing-bottom,
    0.333333em
  );

  /* RADIUS 
   * 1. TODO: revamp introduces new custom sizes that conflict with existing naming convention for PCS radius,
   *    In the future we will need to remap and rename these.
   *    `--radius-12px` to `--radius-x-large`
   *    `--radius-x-large` to `--radius-xx-large`
   */
  --radius-small: var(--cosmos-radius-small, 2px);
  --radius-medium: var(--cosmos-radius-medium, 6px);
  --radius-large: var(--cosmos-radius-large, 8px);
  --radius-12px: 12px; /* 1 */
  --radius-x-large: 16px; /* Custom size, not part of Cosmos */
  --radius-full: var(--cosmos-radius-full, 9999px);

  /* SPACING */
  --spacing-xx-tight: var(--cosmos-spacing-xx-tight, 4px);
  --spacing-x-tight: var(--cosmos-spacing-x-tight, 8px);
  --spacing-tight: var(--cosmos-spacing-tight, 12px);
  --spacing-normal: var(--cosmos-spacing-normal, 16px);
  --spacing-loose: var(--cosmos-spacing-loose, 24px);
  --spacing-x-loose: var(--cosmos-spacing-x-loose, 32px);
  --spacing-xx-loose: var(--cosmos-spacing-xx-loose, 40px);
  --spacing-xxx-loose: var(--cosmos-spacing-xxx-loose, 64px);
  --spacing-xxxx-loose: var(--cosmos-spacing-xxxx-loose, 80px);

  /* Z-INDEX */
  --z-index-disclaimer: 999;
  --z-index-header: 1000;
  --z-index-lifecycle-nav: 101;

  /* BACKDROP BLUR */
  --backdrop-blur-medium: var(--cosmos-backdrop-blur-medium, 20px);

  /* BORDERS */
  --border-thin: var(--cosmos-border-thin, 1px);
  --border-medium: var(--cosmos-border-medium, 2px);

  /* BOX-SHADOWS */

  /* prettier-ignore */
  --elevation-1-above: var(--cosmos-elevation-1-above, 0px 8px 12px 0px #000f1e0a, 0px 2px 4px 0px #000f1e05);

  /* prettier-ignore */
  --elevation-2-above: var(--cosmos-elevation-2-above, 0px 16px 24px 0px #000f1e0f, 0px 4px 8px 0px #000f1e05);

  /* TOKENS BELOW ↓
     Allow for quicker global changes, rather than referencing a color/value directly */

  /* BUTTON TOKENS */
  --button-min-width: auto; /* Stop buttons overflowing on screens < 300px */

  /**
   * Consistent spacing around buttons, Cosmos team plans to add some guidance for this, 
   * so making this global var for easier updates
   * NOTE: These styles are deprecated and now only for Contentful CMS components 
   */
  --button-wrapper-row-gap: var(--spacing-normal, 16px); /* deprecated */
  --button-wrapper-column-gap: var(--spacing-loose, 24px); /* deprecated */
  --button-wrapper-block-start: var(--spacing-x-loose, 32px); /* deprecated */
  --button-wrapper-block-end: var(--spacing-x-loose, 32px); /* deprecated */
  --slider-button-wrapper-gap: var(--spacing-x-tight, 8px);

  /* Used when red button is on red background */
  --button-primary-dark-bg: var(--cosmos-color-surface-solid-dark);
  --button-primary-dark-color: var(--color-text-light);
  --button-primary-light-bg: var(--cosmos-color-surface-solid-light);
  --button-primary-light-color: var(--color-text-dark);

  /* TEXT TOKENS */
  --color-text: var(--cosmos-color-text-dark, #000f1e);
  --color-text-heading: var(--cosmos-color-text-dark, #000f1e);
  --color-text-subtle: var(
    --cosmos-color-text-dark-subtle,
    rgba(0, 15, 30, 0.6)
  );

  /* MISC COLOURS */
  --color-sugar-free: #009edf;
  --color-blue-dark: #001e3c;
  --color-transparent: rgba(255, 255, 255, 0);

  /* BACKGROUND TOKEN */

  /* Use on non-white backgrounds, and panels. 
   * This might need to be expanded into panels and backgrounds later */
  --color-bg-light: var(--cosmos-color-surface-solid-light-lighter);
  --color-bg-shade: var(--color-surface-solid-light);

  /* KICKER */

  /* Used when kicker is on red background */
  --kicker-dark-border-color: var(--cosmos-color-surface-glass-dark-20);
  --kicker-light-border-color: var(--cosmos-color-surface-glass-light-20);

  /* THEMING
   * See `background-wrapper`, due to inheritance of variables set in the html */
  --can-hero-shadow-values: 36px 31px 99px;
  --can-hero-shadow-color-rgb: 0 15 30; /* RGB format, no commas */
  --can-hero-shadow-alpha: 0.6;

  --can-subtle-shadow-values: 17px 15px 70px;
  --can-subtle-shadow-color-rgb: 0 15 30; /* RGB format, no commas */
  --can-subtle-shadow-alpha: 0.5;

  /* shadow with reduced size for FAQ stage */
  --can-tiny-shadow-values: 5px 5px 30px;
  --can-tiny-shadow-color-rgb: 0 15 30; /* RGB format, no commas */
  --can-tiny-shadow-alpha: 0.5;

  /* TEASER BASIC - kicker, title, text combo  */
  --teaser-basic-header-gap: var(--cosmos-spacing-normal, 16px);
  --teaser-basic-content-top-margin: var(--cosmos-spacing-loose, 24px);

  /* BOX INFORMATION HIGHLIGHT */
  --box-information-highlight-desktop-width: 756px; /* magic number, see figma */
  --box-information-highlight-icon-size: 26px; /* magic number, taken from figma */

  @media (width >= 300px) {
    /* BUTTON TOKENS */
    --button-min-width: 200px;
  }

  @media (width >= 650px) {
    /* NAVIGATION */
    --navbar-height: 68px;

    /* CONTAINER WIDTHS & PADDING */
    --spacing-top: 40px;
    --spacing-bottom: 160px;
    --spacing-sides: 40px;
  }

  @media (width >= 1000px) {
    /* CONTAINER WIDTHS & PADDING */
    --spacing-top: 64px;
    --spacing-bottom: 124px;
    --spacing-sides: 64px;

    /* Buttons */
    --button-wrapper-block-start: var(--spacing-xx-loose, 40px);
    --button-wrapper-block-end: var(--spacing-xx-loose, 40px);
  }
}
