/* 
 * All heading elements are completely unstyled by default, and have the same font-size and font-weight as normal text.
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

/** 
 * Remove all of the default margins from elements like headings, blockquotes, paragraphs, etc.
 */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

/** 
 * Accessibility considerations:
 * Unstyled lists are not announced as lists by VoiceOver/Safari. 
 * If your content is truly a list but you would like to keep it unstyled, add a `role="list"` to the element.
 * This forces the developer to consciously remove list style, or add the role.
 */
ol:where([role="list"]),
ul:where([role="list"]) {
  list-style: none;
}

/*
Reset default styling for dialogs.
*/
dialog {
  padding: 0;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
img,
video {
  max-width: 100%;
  height: auto;
}
